import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import currentOpeningStyle from "ps-assets/jss/currentOpeningStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { graphql } from "gatsby";
import classNames from "classnames";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import HorizontalTable from "components/HorizontalTable/HorizontalTable";
import Markdown from "markdown-to-jsx";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import GridItem from "components/Grid/GridItem";
import Pagination from "components/Pagination/Pagination";
import getPages from "util/getPages.jsx";
import { Helmet } from "react-helmet";


let url = process.env.BASE_URL;
const fieldsPerPage = 3;

const CurrentOpening = ({ classes, data }) => {
  const [currentState, changeState] = useState(``);
  const [currentPage, changePage] = useState(0);
  const [jobList, changeJobList] = useState([]);
  useEffect(() => {
    var list = [];
    data &&
      data.allStrapiSections &&
      data.allStrapiSections.edges &&
      data.allStrapiSections.edges[currentState] &&
      data.allStrapiSections.edges[currentState].node &&
      data.allStrapiSections.edges[currentState].node.jobs &&
      data.allStrapiSections.edges[currentState].node.jobs.map(job => {
        var temp = [];
        temp.push(job.designation);
        temp.push(job.noOfVacancies);
        temp.push(job.qualification);
        temp.push(job.location);
        temp.push(job.ctc);
        temp.push(
          job.hrMailId ? (
            <a href={`mailto:${job.hrMailId}`}>{job.hrMailId}</a>
          ) : (
            job.hrMailId
          )
        );
        temp.push(
          job.jobProfile ? (
            <Markdown>{job.jobProfile}</Markdown>
          ) : (
            job.jobProfile
          )
        );
        temp.push(job.eligibility);
        list.push(temp);
      });
    changeJobList(list);
  }, [currentState]);

  let jobs = jobList.slice(0);
  return (
    <Layout
      image2={data.allStrapiImages.edges[0].node.content.childImageSharp.fluid}
      small={true}
    >
      <Helmet key="helmetTags">
        <title>Careers |StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `careers`}
          />
          <meta name="title" content="Careers|StarHealth.in" />
          <meta name="twitter:title" content="Careers|StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance Current Openings"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Careers|StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance Current Openings" />
          <meta property="og:url" content={url + `careers`}/>
          <meta name="Careers|StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance Current Openings" />
          <meta property="twitter:url" content={url + `careers`} />
        </Helmet>
      )}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer className={`${classes.container} `}>
              <GridItem
                className={`${classes.mlAuto} ${classes.textCenter} ${classes.labelGrid}`}
                xs={12}
                sm={6}
                md={2}
              >
                <InputLabel
                  htmlFor="current-opening"
                  className={classes.selectLabel}
                >
                  View Current Openings
                </InputLabel>
              </GridItem>
              <GridItem
                className={`${classes.mrAuto} ${classes.griditemAlignment}`}
                xs={12}
                sm={6}
                md={2}
              >
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel
                    htmlFor="select-state"
                    className={classes.selectLabel}
                  >
                    Select State
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={currentState}
                    onChange={e => changeState(e.target.value)}
                    style={{ display: `block` }}
                    inputProps={{
                      name: `select-state`,
                      id: `select-state`
                    }}
                    input={<Input id="name-error" />}
                  >
                    {data.allStrapiSections.edges.map((edge, key) => {
                      return (
                        <MenuItem
                          key={key}
                          classes={{
                            root: classes.selectMenuItem
                          }}
                          value={key}
                        >
                          {edge.node.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              {jobs.length != 0 && (
                <GridItem md={12}>
                  <GridItem className={classes.setMargin} md={10}>
                    <HorizontalTable
                      tableHeaderColor="primary"
                      tableData={jobs.slice(
                        currentPage * fieldsPerPage,
                        (currentPage + 1) * fieldsPerPage
                      )}
                      tableHead={[
                        `Designation`,
                        `No. of \n Vacancies`,
                        `Qualification`,
                        `Location`,
                        `CTC`,
                        `HR Mail ID`,
                        `Job Profile`,
                        `Eligibility`
                      ]}
                    />
                  </GridItem>
                  <GridItem style={{ display: `flex` }} md={12}>
                    <Pagination
                      className={classNames(
                        classes.mrAuto,
                        classes.mlAuto,
                        classes.paginationScroll
                      )}
                      pages={getPages(
                        jobs,
                        currentPage,
                        fieldsPerPage,
                        changePage
                      )}
                    />
                  </GridItem>
                </GridItem>
              )}
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

CurrentOpening.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(currentOpeningStyle)(CurrentOpening);

export const currentOpeningQuery = graphql`
  query currentOpening {
    allStrapiImages(filter: { category: { eq: "current_opening" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiSections(filter: { category: { eq: "current_career_opening" } }) {
      edges {
        node {
          title
          jobs {
            designation
            noOfVacancies
            qualification
            location
            ctc
            hrMailId
            jobProfile
            eligibility
          }
        }
      }
    }
  }
`;
