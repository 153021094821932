import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised,
  section
} from "assets/jss/material-kit-pro-react.jsx";

import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

delete customSelectStyle.select.fontSize;

const currentOpeningStyle = {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  main,
  mainRaised: {
    marginBottom: 100,
    ...mainRaised
  },
  section,
  ...customSelectStyle,
  setMargin: {
    marginLeft: `auto`,
    marginRight: `auto`,
    marginBottom: `5%`
  },
  textCenter: {
    textAlign: `center`,
    paddingTop: `1%`
  },
  labelGrid: {
    marginBottom: 50,
    paddingTop: 80,
    "@media (max-width:420px)": {
      paddingTop: 20,
      marginBottom: 20
    },
    "& label": {
      fontWeight: `bold`
    }
  },
  griditemAlignment: {
    marginBottom: 50,
    paddingTop: 50,
    "@media (max-width:420px)": {
      paddingTop: 20,
      marginBottom: 20
    }
  },
  paginationScroll: {
    overflow: `auto`
  },
  selectLabel: {
    ...customSelectStyle.selectLabel,
    fontSize: `16px`
  },
  borderName: {
    borderBottom: `none`,
    borderRadius: `0px`,
    "& th": {
      border: `solid thin #dbdbdb`,
      background: `#35488a`,
      color: `#fff`
    },
    "& td": {
      border: `solid thin #dbdbdb`
    }
  }
};

export default currentOpeningStyle;
