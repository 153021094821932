import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import style from "assets/jss/material-kit-pro-react/components/tableStyle.jsx";

const horizontalTableData = (tableHead, tableData) =>
  tableHead.map((head, index) => {
    return { title: head, data: tableData.map(data => data[index]) };
  });

function CustomTable({ ...props }) {
  const { classes, tableHeaderColor, tableData, tableHead } = props;
  const hr_table_data = horizontalTableData(tableHead, tableData);
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {hr_table_data &&
          hr_table_data.map(row => (
            <TableRow className={classes.tableRow}>
              {row.title && (
                <div>
                  <TableHead className={classes[tableHeaderColor]}>
                    <TableCell
                      className={
                        classes.tableHeadCell + " " + classes.tableCell
                      }
                    >
                      {row.title}
                    </TableCell>
                  </TableHead>
                </div>
              )}
              {row.data &&
                row.data.map(content => (
                  <TableCell className={classes.tableCell}>{content}</TableCell>
                ))}
            </TableRow>
          ))}
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: []
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool
};

export default withStyles(style)(CustomTable);
